<template lang="pug">
#search
    form(action="/")
        van-search(
            left-icon="mysearch",
            v-model="value",
            show-action,
            placeholder="请输入搜索关键词",
            @input="onSearch",
            @cancel="onCancel"
        )
    .search-box(v-if="!list.length")
        h2.search-title 热门搜索
        ul.search-li(v-for="item in hot_search")
            li(@click="onSearch(item.title)") {{ item.title }}
    //- .search-box
    //-     h2.history-title 历史搜索
    //-     ul.search-li
    //-         li 20万的车
    .xin-box(v-if="list.length", v-for="item in list") 
        .xin-li(@click="detail(item.id)")
            .imgbox(
                :style="{ backgroundImage: 'url(' + item.thum_image_url + ')' }"
            )
                //- img(:src="item.thum_image_url")

            .namebox 
                span.test-img(v-if="item.is_check_car == 1")
                    img(src="../../assets/mine/test.png")
                span.name {{ item.title }}
                span.xinup {{ item.title_tag }}
            .numbox
                .numbox-l
                    span.num-1 订金 ¥
                    span.num-2 {{ item.deposit }}
                .numbox-r
                    span.num-1 指导价：{{ item.guide_price }}

    van-empty(image="search", description="未搜索到相关车辆", v-if="searchResult")
</template>
<script>
import { getSearch, search } from "../../config/request";
import ShareImpl from '@/weixinApi.js';

export default {
    name: "search",
    data() {
        return {
            value: "",
            hot_search: [],
            member_search: [],
            list: [],
            searchResult: false,
            ft: 0,
        };
    },
    activated() {
        if (this.$route.query.from != this.ft) {
            this.ft = this.$route.query.from;
            this.list = [];
            this.value = "";
            this.getData();
        }
    },

    methods: {
        onSearch(val) {
            this.value = val.trim();
            if (val.length <= 0) {
                return;
            }

            this.searchResult = false;

            search({ content: val }).then((res) => {
                if (res.code === 20000) {
                    this.list = res.data;
                    if (res.data.length <= 0) {
                        this.searchResult = true;
                    }
                }
            });
        },
        onCancel() {
            this.$router.push({ path: "/" });
        },

        //获取数据
        getData() {
            getSearch().then((res) => {
                if (res.code === 20000) {
                    this.hot_search = res.data.hot_search;
                    this.member_search = res.data.member_search;
                    let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res,11111);
                    let img = window.location.href.split("#")[0] + 'd.jpg';
                    let shareTitle = '链上汽车';
                    let shareDesc = res.data.share ? res.data.share.share_title : '';
                    console.log(shareDesc,'分享title')
                    let shareImg =  img;
                    // let shareImg = img;
                    console.log(shareImg,'图片111')
                    console.log(img,'本地111')
                    ShareImpl({
                      shareTitle,
                      shareDesc,
                      shareUrl:  location.origin + location.pathname + "?share=true/" + location.hash,
                      shareImg
                    });
                }
            });
        },

        detail(val) {
            this.$router.push({ path: "/detail?id=" + val });
        },
    },
};
</script>

<style lang="stylus" scoped>
#search
    // background #ffffff

.van-cell
    font-size 16px !important
    padding 10px 8px 10px 0 !important

.van-search
    padding 21px 0 21px 12px !important

.van-search__content
    border-radius 6px !important

.van-icon-mysearch::before
    content ''
    width 15px
    height 15px
    background url('../../assets/mine/search.png') no-repeat center / 15px 15px
    margin-right 13px

.van-search__action
    font-size 16px !important

.search-box
    border-top 1px solid #f5f5f5
    padding 20px 0 11px 12px
    background #ffffff

.history-title
    font-size 16px
    font-family PingFang SC
    color #333333
    line-height 22px
    font-weight bold
    padding-bottom 11px
    background url('../../assets/mine/delete.png') no-repeat right 15px center / 14px 14px

.search-title
    font-size 16px
    font-family PingFang SC
    color #333333
    line-height 22px
    font-weight bold
    padding-bottom 11px

.search-li
    display inline-block

li
    display inline-block
    font-size 14px
    border 1px solid #F2F2F2
    color #333333
    padding 3px 12px
    border-radius 15px
    line-height 22px
    margin-right 12px
    margin-bottom 10px

.xin-box
    margin-top 10px

    .xin-li
        background #ffffff
        padding 12px 12px 7px
        margin-bottom 12px

        .imgbox
            border-radius 6px
            position relative
            width 100%
            height 250px
            background-position center center
            background-repeat no-repeat
            background-size cover

            img
                border-radius 6px

            .posi-li
                position absolute
                display flex
                flex-direction column
                justify-content center
                align-items center
                top 0
                left 6px
                color #ffffff
                font-size 12px
                background url('../../assets/mine/cheng.png') no-repeat center / 46px 54px
                // padding 4px 7px 18px
                width 46px
                height 54px

                span
                    color #ffffff
                    font-size 12px
                    // padding-top 4px

        .namebox
            text-align justify
            margin-top 10px
            margin-bottom 7px

            .test-img
                display inline-block
                vertical-align middle
                height 18px
                margin-right 6px

            .name
                font-size 20px
                color #333333
                line-height 30px
                font-weight normal
                vertical-align middle

            .xinup
                font-weight normal
                display inline-block
                background #fdf4ed
                border 1px solid #fdf4ed
                border-radius 14px
                border-bottom-left-radius 0px
                font-size 12px
                color #EA5945
                line-height 20px
                padding 0px 10px
                margin-left 9px
                vertical-align top
                margin-top 2px

        .numbox
            display flex
            justify-content space-between
            align-items center
            line-height 32px

            .numbox-l
                .num-1
                    font-size 14px
                    color #F23030

                .num-2
                    font-size 22px
                    color #F23030
                    font-weight bold

                .num-3
                    font-size 14px
                    color #F23030

            .numbox-r
                font-size 16px
                color #666666
</style>